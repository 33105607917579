import { basicRequiredRule } from '@/template/rule'
import Validator from '@/validators'
import { getCommonParamsOptions } from '@/utils/common.js'

export const baseUpdateFormConfig = {
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'terminal.model-name',
      otherOptions: {
        maxlength: 26,
        disabled: true
      }
    },
    {
      field: 'order',
      type: 'input',
      label: 'category.order',
      otherOptions: {
        maxlength: 3
      }
    },
    {
      field: 'brandType',
      type: 'select',
      label: 'terminal.brand',
      isResolveGlobalParams: true,
      options: [],
      handler: () =>
        getCommonParamsOptions('brand_type', {
          isNeedPreFix: false
        })
    },
    {
      field: 'appType',
      type: 'select',
      label: 'file.application-type',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('app_type')
    },
    {
      field: 'tenantId',
      type: 'input',
      label: 'user.tenant',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'description',
      type: 'textarea',
      label: 'general.description',
      colLayout: {
        xl: 24,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24
      },
      otherOptions: {
        maxlength: 500
      }
    }
  ],
  validateRules: {
    // name: [basicRequiredRule],
    brandType: [basicRequiredRule],
    appType: [basicRequiredRule],
    order: [
      { validator: Validator.integer, message: 'validator.must-be-integer' }
    ]
  }
}
