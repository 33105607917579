import { getCommonParamsOptions } from '@/utils/common.js'

export const baseViewFormConfig = {
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'terminal.model-name',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'order',
      type: 'input',
      label: 'category.order',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'brandType',
      type: 'select',
      label: 'terminal.brand',
      isResolveGlobalParams: true,
      options: [],
      handler: () =>
        getCommonParamsOptions('brand_type', {
          isNeedPreFix: false
        }),
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'appType',
      type: 'select',
      label: 'file.application-type',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('app_type'),
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'tenantId',
      type: 'input',
      label: 'user.tenant',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'description',
      type: 'textarea',
      label: 'general.description',
      colLayout: {
        xl: 24,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24
      },
      otherOptions: {
        disabled: true
      }
    }
  ]
}
